import styled, {keyframes} from 'styled-components'
import borderRadius from '../../styles/borderRadius'
import space from '../../styles/space'
import hexToRGB from '../../utils/hexToRGB'
import {primaryTitleFont, secondaryTitleFont, articleFont} from '../../styles/fonts'
import {basicallyBlack} from '../../styles/colors'
import {connect} from 'react-redux'
import breakpoints from '../../styles/breakpoints'
// import linkSvg from '../../static/icons/linkSvg.svg'

const linkSvg = `data:image/svg+xml;charset=utf8,%3Csvg id='squiggle-link' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:ev='http://www.w3.org/2001/xml-events' viewBox='0 0 10 18'%3E%3Cpath fill='none' stroke='%2301b96b' stroke-width='1' class='squiggle' d='M0,17.5 c 2.5,0,2.5,-1.5,5,-1.5 s 2.5,1.5,5,1.5 c 2.5,0,2.5,-1.5,5,-1.5 s 2.5,1.5,5,1.5' /%3E%3C/svg%3E`
// const linkSvg = `data:image/svg+xml;charset=utf8,%3Csvg id='squiggle-link' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:ev='http://www.w3.org/2001/xml-events' viewBox='0 0 10 18'%3E%3Cstyle type='text/css'%3E.squiggle%7Banimation:shift .5s linear infinite;%3C@keyframes shift %7Bfrom %7Btransform:translateX(-10px);%3Cto %7Btransform:translateX(0);%3C%3C%3C/style%3E%3Cpath fill='none' stroke='%2301b96b' stroke-width='0.5' class='squiggle' d='M0,17.5 c 2.5,0,2.5,-1.5,5,-1.5 s 2.5,1.5,5,1.5 c 2.5,0,2.5,-1.5,5,-1.5 s 2.5,1.5,5,1.5' /%3E%3C/svg%3E`
// const linkSvg = `url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 18'%3E%3Cstyle type='text/css'%3E.squiggle%7Banimation:shift .5s linear infinite;%7D@keyframes shift %7Bfrom %7Btransform:translateX(-10px);%7Dto %7Btransform:translateX(0);%7D%7D%3C/style%3E%3Cpath fill='none' stroke='%252301b96b' stroke-width='0.5' class='squiggle' d='M0,17.5 c 2.5,0,2.5,-1.5,5,-1.5 s 2.5,1.5,5,1.5 c 2.5,0,2.5,-1.5,5,-1.5 s 2.5,1.5,5,1.5' /%3E%3C/svg%3E")`


const ProjectArticle = styled.article`
  margin-bottom: 10rem;
  z-index: 1;
  transform: translate(0);
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;

  .article-header-header {
    padding-top: 2rem;
  }

  .text {
    &SM {
      font-size: .9rem !important;
    }
  }

  h1, h2, h3 {
    font-weight: 400;
    font-family: ${primaryTitleFont};
  }
  h4, h5, h6 {
    font-family: ${secondaryTitleFont};
  }
  h1 {
    font-size: 3em;
    
    ${breakpoints.md} {
      font-size: 5em;
    }

    &:first-child{
      margin-top: 5rem;
    }
  }
  
  p, ul {
    font-family: ${articleFont};
    font-weight: 500;
    
    ${breakpoints.md} {
      font-size: 1.3em;
    }
  }

  ul {
    padding-left: 40px;
/*     
    ${breakpoints.lg} {
      padding-left: 0;
    } */
  }

  li {
    list-style-type: "👉  "; 
    margin-left: 0;
    
    &:not(:last-child){
      margin-bottom: 1.5rem;
    }
  }

  p {
    &:not(:last-child){
      margin-bottom: 3rem;
    }
  }

  .center, .centerSection {
    text-align: center;
  }

  .section, .centerSection {
    margin-top: 5rem;
  }
  
  a {
    position: relative;
    overflow: hidden;
    text-decoration: none;
    z-index: 2;
    /* color: #333; */
    color: ${({theme, reduxTheme}) => theme.colorScheme[reduxTheme.colorScheme].primaryFontColor};
    
    &::after {
      content: "";
      /* background: rgba(51,51,51, 0.25); */
      position: absolute;
      left: 12px;
      bottom: -6px;
      width: calc(100% - 8px);
      height: calc(100% - 8px);
      z-index: -1;
      transition: 0.35s cubic-bezier(0.25, 0.1, 0, 2.05);
      background-image: linear-gradient(45deg, #FD8BA9, #9A94E8, #2EDBF8, #F5F1C8, #FD8BA9) !important; 
      opacity: 0.25;

    }

    &:hover:after {
        left: 0;
        bottom: -2px;
        width: 100%;
        height: 100%;
        opacity: 0.6;
    }
  }
  .cta {
    text-align: center;
    padding-top: 5rem;

    a {
      border-bottom: none;
      ${borderRadius}
      font-family: ${secondaryTitleFont};
      padding: 2rem;
      box-shadow: 0px ${space.xl*.4}px ${space.xl}px rgba(${hexToRGB(basicallyBlack)}, 0.25);
      transition: box-shadow .1s ease-in-out;
      color: ${({theme, reduxTheme}) => theme.colorScheme[reduxTheme.colorScheme].primaryFontColor};

      /* background-color: ${({theme, reduxTheme}) => theme.colorScheme[reduxTheme.colorScheme].primaryButtonColor}; */
      &::after {
      content: none;
      }

      &:hover {
        box-shadow: 0px ${space.xl*.2}px ${space.xl/2}px rgba(${hexToRGB(basicallyBlack)}, 0.25);
      }
      &:active {
        box-shadow: 0px ${space.xl*.1}px ${space.xl/4}px rgba(${hexToRGB(basicallyBlack)}, 0.25);
      }
    }
  }
  
  img {
    margin: auto;
    ${borderRadius}
    /* max-width: 800px; */
    box-shadow: 0px ${space.xl*.4}px ${space.xl}px rgba(${hexToRGB(basicallyBlack)}, 0.25);
    max-width: 100%;


    ${breakpoints.md} {
      max-width: 90%;
      /* max-width: 140%;
      margin: auto;
      position: relative;
      left: 50%;
      transform: translateX(-50%); */
    }
  }

  p > img {
    text-align: center;
  }

`

export default connect(({theme}) => ({reduxTheme: theme}))(ProjectArticle)
