import React from "react";
import { graphql } from "gatsby"
import Container from '../components/styled/Container'
import Row from '../components/styled/Row'
import PrevNext from '../components/PrevNext'
import styled from 'styled-components'
import borderRadius from '../styles/borderRadius'
import {animateChildren, inAndUp, fadeIn, extraLong} from '../styles/animation'
import {basicallyBlack} from '../styles/colors'
import hexToRGB from '../utils/hexToRGB'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { MDXProvider } from "@mdx-js/react"
import { Link } from "gatsby"

import {motion} from 'framer-motion'
import ParallaxPageTitle from '../components/motion/ParallaxPageTitle'
import Slider from '../components/motion/Slider'
import ProjectArticle from '../components/styled/ProjectArticle'
import To from '../components/styled/To'
import space from '../styles/space'

const shortcodes = { Link, To, Slider } // Provide common components here

const StyledImage = styled(motion.img).attrs({ 
  ...fadeIn, 
  ...extraLong 
})`
  width: 100%;
  z-index: 2;
  margin-top: 5rem;
  transform: translateZ(10px);
  object-fit: cover;

  max-width: ${({landscape}) => landscape ? '750px' : '525px'};
  flex-grow: 1;
  box-shadow: 0px ${space.xl*.4}px ${space.xl}px rgba(${hexToRGB(basicallyBlack)}, 0.25);
  ${({br = true}) => br && borderRadius}
`;

const StyledPageTitle = styled(ParallaxPageTitle)`
  z-index: -1;
  font-size: 20em;
`;

const ProjectPage = ({data, pageContext}) => {
  const { mdx } = data // data.mdx holds your post data
  if(!mdx) return null;
  const { frontmatter, html, body } = mdx
  const {title, date, featuredImg} = frontmatter;

  return (
    <Container>
      <StyledPageTitle offset={34}>
        <Row justify="center">
          <StyledImage src={featuredImg} landscape />
        </Row>
      </StyledPageTitle>
      
      <ProjectArticle>
        <MDXProvider components={shortcodes}>
          <MDXRenderer>{body}</MDXRenderer>
        </MDXProvider>
      </ProjectArticle>

      <PrevNext {...pageContext} />
    </Container>
  );
}

export default ProjectPage;

// # markdownRemark(frontmatter: { path: { eq: $path } }) {
export const pageQuery = graphql`
  query($slug: String!) {
      mdx(frontmatter: {path: {eq: $slug}}) {
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        featuredImg
      }
      body
    }
  }
`