import React from 'react'
import Row from './styled/Row'
import Col from './styled/Col'
import SectionTitle from './styled/SectionTitle'
import ProjectCard from './motion/ProjectCard'

const PrevNext = ({prev, next}) => {
  return (
    <>
      {/* <SectionTitle>
        Go on...
      </SectionTitle> */}
      <Row>
        <Col xs={1} lg={.5}>
          {prev && prev.frontmatter && (
            <ProjectCard {...prev}
            />
          )}
        </Col>
        <Col xs={1} lg={.5}>
          {next && next.frontmatter && (
            <div>
                <ProjectCard {...next}
                />
            </div>
          )}  
        </Col>
      </Row>
    </>
  )
}

export default PrevNext