import React from 'react'
import config from '../config/siteConfig'
import url from 'url'

const {cloudinaryUrl, projectImageDefaultScale} = config

const CloudImage = ({scale = projectImageDefaultScale, src, className, onClick}) => {
  let _scale = '', _src = '';
  
  if(!src.includes('c_scale') && scale)
    _scale = `/c_scale,${scale}/`;

  if(!src.includes('http')) 
    _src =`${cloudinaryUrl}${_scale}${src}`.replace(/\/\/+/g, '/').replace('https:/', 'https://').replace('http:/', 'http://');

  return (
    <img src={_src} className={className} onClick={onClick} />
  )
}

export default CloudImage