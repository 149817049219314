import React, { useState, useRef, useLayoutEffect } from 'react'
import { motion, useViewportScroll, } from "framer-motion";
import {quaternaryTitleFont} from '../../styles/fonts'
import rand from '../../utils/rand';
import styled, {css} from 'styled-components'
import space from '../../styles/space'
import {basicallyBlack} from '../../styles/colors'
import borderRadius from '../../styles/borderRadius'
import hexToRGB from '../../utils/hexToRGB'
import {fadeIn, extraLong} from '../../styles/animation'
import AnimateIn from './AnimateIn'
import CloudImage from '../CloudImage'
import {cloudinaryUrl, projectImageDefaultSize} from '../../config/siteConfig'
import url from 'url'
import { ChevronRight, ChevronLeft  } from 'react-feather';
import {connect} from 'react-redux'
import breakpoints from '../../styles/breakpoints'

const StyledSlider = styled.div`
  position: relative;
  user-select: none;
  
  ${breakpoints.md} {
    width: 90%;
    margin: auto;

    ${({size}) => size === 'mobile' && `
      height: 720px;
      width: 100% !important;
    `}
  }
  
  ${breakpoints.xl} {
    width: 80%;
    margin: auto;
  }
`;

const StyledControl = styled.div`
  background-color: ${({theme, reduxTheme}) => theme.colorScheme[reduxTheme.colorScheme].primaryColor};
  padding: .25rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  top: 50%;
  transform: translateY(-50%);
  user-select: none;

  &:not(:first-child) {
    right: 0%;
  }

  ${breakpoints.md} {
    padding: 1rem;
  }
`


const Slide = styled(CloudImage)`
  cursor: pointer;
  display: ${({active}) => active ? 'block' : 'none'};
  
`

const SlideControls = ({reduxTheme, innerOnClick}) => {
  return (
    <>
      <StyledControl reduxTheme={reduxTheme} onClick={() => {innerOnClick('prev')}}>
        <ChevronLeft size={32} />
      </StyledControl>
      <StyledControl reduxTheme={reduxTheme} onClick={() => {innerOnClick('next')}}>
        <ChevronRight size={32} />
      </StyledControl>
    </>
  )
}

const Slider = ({height, width, slides, scale='h_720', reduxTheme, size="desktop"}) => {
  const [active, setActive] = useState(0);

  return (
    <StyledSlider size={size}>
      <SlideControls reduxTheme={reduxTheme} innerOnClick={val => {
        switch(val) {
          case 'prev': 
            if(active - 1 < 0)
              setActive(slides.length - 1)
            else 
              setActive((active - 1) % slides.length)
            break;
          case 'next':
            setActive((active + 1) % slides.length)
            break;
        }
      }}/>
      {slides.map((slide, i) => (
        <Slide 
          key={i} 
          scale={scale} 
          src={slide}   
          active={i === active} 
          onClick={() => {
            setActive((i + 1) % slides.length)
          }}
        />
      ))}
    </StyledSlider>
  )
}

// export default Slider
export default connect(({theme}) => ({reduxTheme: theme}))(Slider)
