import React from 'react'
import { motion, } from "framer-motion";
import {quaternaryTitleFont} from '../../styles/fonts'
import styled from 'styled-components'
import space from '../../styles/space'
import {basicallyBlack} from '../../styles/colors'
import borderRadius from '../../styles/borderRadius'
import hexToRGB from '../../utils/hexToRGB'
import AnimateIn from './AnimateIn'
import breakpoints from '../../styles/breakpoints';
import {connect} from 'react-redux'
import DoubleParallax from './DoubleParallax';
import { Link } from "gatsby";

const StyledImage = styled(motion.img)`
  width: 100%;
  cursor: pointer;
  z-index: 2;
  object-fit: cover;

  ${({index}) => index % 2 ? (`
    margin-right: auto;
  `) : (`
    margin-left: auto;
  `)};

  height: 150px;
  /* max-width: ${({landscape}) => landscape ? '798px' : '532px'}; */
  max-width: 798px;
  flex-grow: 1;
  box-shadow: 0px ${space.xl*.4}px ${space.xl}px rgba(${hexToRGB(basicallyBlack)}, 0.25);
  ${({br = true}) => br && borderRadius}
` 

const StyledProjectTitle = styled.h2`
  font-family: ${quaternaryTitleFont};
  font-weight: 900;
  z-index: 3;
  padding: 1rem;

  ${breakpoints.md} {
    font-size: 3rem;
  }
`

const StyledProject = styled.div`
  position: relative;
  padding: 1rem;

  ${breakpoints.md} {
    padding-left: 2rem;
    padding-right: 2rem;
  }
`

const Project = ({landscape, index, reduxTheme, mouse, scroll, frontmatter: {title, date, featuredImg}}) => (
  <StyledProject reduxTheme={reduxTheme} index={index}>
    <StyledImage src={featuredImg}  index={index} landscape={landscape} />
  
    <StyledProjectTitle index={index} reduxTheme={reduxTheme}>
      {title}
    </StyledProjectTitle>
  </StyledProject>
)

const ProjectCard = ({ fields, ...rest}) => {
  const link = rest.frontmatter.path;

  return (
    <AnimateIn>
      {link ? (typeof link === 'string' && link.includes('http') ? (
        <a href={link} target="_blank">
          <Project {...rest} />
        </a>
      ) : (
        <Link to={link}>
          <Project {...rest} />
        </Link>
      )): (
        <Project {...rest} />
      )}
    </AnimateIn>
  );
};
export default connect(({theme}) => ({reduxTheme: theme}))(ProjectCard)